var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "53",
        height: "49",
        viewBox: "0 0 53 49",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M52.674 18.716C53.1717 15.7938 52.8437 12.7905 51.727 10.0446C50.6102 7.2987 48.7491 4.9193 46.3531 3.17398C43.7474 1.31207 40.6142 0.332785 37.412 0.37979C35.3888 0.393354 33.3887 0.81141 31.5293 1.60928C29.67 2.40716 27.9889 3.56873 26.585 5.02579C24.4458 2.844 21.7009 1.35469 18.7056 0.75064C15.7103 0.146594 12.6027 0.455698 9.78508 1.63785C7.90424 2.41006 6.20381 3.56359 4.79106 5.02579C3.32482 6.50277 2.17233 8.2612 1.403 10.195C0.637549 12.0438 0.246032 14.026 0.251024 16.027C0.248436 18.0391 0.639773 20.0322 1.403 21.8939C2.16761 23.8199 3.32099 25.5676 4.79106 27.028L25.4671 47.913C25.6124 48.061 25.7864 48.1777 25.9786 48.2558C26.1707 48.3339 26.3767 48.3715 26.584 48.3669C26.786 48.3692 26.9863 48.3302 27.1725 48.2521C27.3588 48.174 27.5271 48.0587 27.667 47.913L48.377 26.9579C50.6508 24.7381 52.1572 21.8509 52.677 18.716",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }