var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "54",
        height: "49",
        viewBox: "0 0 54 49",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M48.125 13.728C46.1431 9.76458 43.0967 6.4312 39.3271 4.10156C35.5576 1.77193 31.2138 0.538086 26.7825 0.538086C22.3511 0.538086 18.0073 1.77193 14.2378 4.10156C10.4682 6.4312 7.42181 9.76458 5.43994 13.728C4.05712 13.7622 2.74252 14.3357 1.77673 15.3259C0.810949 16.3162 0.270574 17.6448 0.270996 19.0281V29.6279C0.270996 31.0336 0.829422 32.3818 1.82336 33.3757C2.81731 34.3697 4.1654 34.928 5.57105 34.928C6.97669 34.928 8.32478 34.3697 9.31873 33.3757C10.3127 32.3818 10.871 31.0336 10.871 29.6279V19.0281C10.8436 18.0889 10.5676 17.1736 10.071 16.376C11.5909 13.2385 13.9636 10.5926 16.9176 8.74121C19.8716 6.88984 23.2874 5.90796 26.7736 5.90796C30.2598 5.90796 33.6754 6.88984 36.6294 8.74121C39.5834 10.5926 41.9561 13.2385 43.476 16.376C42.9798 17.1738 42.7039 18.089 42.676 19.0281V29.6279C42.7039 30.5671 42.9798 31.4822 43.476 32.28C41.9751 35.4418 39.6108 38.1149 36.6559 39.9905C33.7009 41.8661 30.2759 42.8676 26.776 42.8799C26.0858 42.8988 25.4302 43.1865 24.9487 43.6814C24.4672 44.1763 24.1978 44.8396 24.1978 45.53C24.1978 46.2205 24.4672 46.8838 24.9487 47.3787C25.4302 47.8736 26.0858 48.161 26.776 48.1799C31.2144 48.1739 35.563 46.9301 39.3334 44.5884C43.1037 42.2466 46.1463 38.8999 48.119 34.9241C49.5018 34.8899 50.8164 34.3164 51.7822 33.3262C52.748 32.3359 53.2884 31.0073 53.288 29.624V19.0239C53.2884 17.6407 52.748 16.3123 51.7822 15.322C50.8164 14.3318 49.5018 13.7583 48.119 13.7241",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.434 37.5891C30.1242 37.5702 30.7799 37.2825 31.2614 36.7876C31.7428 36.2927 32.0122 35.6295 32.0122 34.939C32.0122 34.2485 31.7428 33.5853 31.2614 33.0904C30.7799 32.5955 30.1242 32.308 29.434 32.2891H24.134C23.7799 32.2794 23.4274 32.3407 23.0974 32.4695C22.7674 32.5983 22.4664 32.7918 22.2125 33.0388C21.9586 33.2859 21.7568 33.5814 21.619 33.9077C21.4812 34.2341 21.4102 34.5847 21.4102 34.939C21.4102 35.2933 21.4812 35.6439 21.619 35.9702C21.7568 36.2966 21.9586 36.5921 22.2125 36.8391C22.4664 37.0862 22.7674 37.2799 23.0974 37.4087C23.4274 37.5375 23.7799 37.5989 24.134 37.5891H29.434Z",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }