<template>
    <div class="prefooter-blocks-large">
        <b-row>
            <!--
            <b-col sm="12" md="6" xl="3" class="block-wrap">
                <div class="block">
                    <Check />
                    <strong>Conferma immediata</strong>
                    <p>
                        Prenoti viaggi con pochi click e in tempo reale. Nessun preventivo, nessun voucher.
                    </p>
                </div>
            </b-col>
            -->
            <b-col sm="12" xxl="3" class="block-wrap">
                <div class="block">
                    <DoubleCheck />
                    <strong>Double check</strong>
                    <p>
                        Dopo la conferma online verifichiamo di nuovo la prenotazione qualche giorno prima del tuo arrivo.
                    </p>
                </div>
            </b-col>
            <b-col sm="12" xxl="3" class="block-wrap">
            <div class="block">
                <HeartLarge />
                    <strong>White list</strong>
                    <p>
                        La nostra tecnologia e le nostre persone lavorano ogni giorno per proporti solo strutture di provata qualità e professionalità.
                    </p>
                </div>
            </b-col>
            <b-col sm="12" xxl="3" class="block-wrap">
                <div class="block">
                    <Headset />
                    <strong>Assistenza interna</strong>
                    <p>
                        Ti assistiamo con persone che potrai chiamare per nome. Nessun call center esterno o robot.
                    </p>
                </div>
            </b-col>
        </b-row>
    </div>    
</template>

<script>
//import Check from '../../svg/check'
import DoubleCheck from '../../svg/double-check'
import HeartLarge from '../../svg/heart-large'
import Headset from '../../svg/headset'
export default {
    name: 'prefooter-white',
    components:
    {
        //Check,
        DoubleCheck, HeartLarge, Headset
    }
}
</script>

<style lang="scss">
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/mixins";
  @import '../../../scss/_custom.scss';
  .prefooter-blocks-large
  {
    margin:0 auto;
    $block-pad: 0 $grid-gutter-width/4;
    .row
    {
      justify-content:center;
    }
    .block-wrap
    {
      //&:not(:last-child)
      //{
        margin-bottom:2rem;
      //}
    }
    .block
    {
      padding:$block-pad;
      margin-bottom:$block-pad;
      display:flex;
      flex-direction: column;
      align-items: center;
      text-align:center;
      height:100%;
      p
      {
        max-width: 100%;
      }
    }
    svg
    {
      margin-bottom:2rem;
    }
    strong
    {
      margin-bottom:1rem;
      font-size:20px
    }
    p
    {
      font-size:16px;
      margin-bottom:0;
    }
  }
</style>