var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "101",
        height: "59",
        viewBox: "0 0 101 59",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M93.007 10.69C89.3149 6.99801 84.6108 4.48372 79.4897 3.46516C74.3687 2.4466 69.0606 2.96966 64.2367 4.96785C59.4128 6.96603 55.2897 10.3498 52.3889 14.6912C49.4881 19.0327 47.9398 24.1366 47.9398 29.358C47.9398 34.5794 49.4881 39.6835 52.3889 44.025C55.2897 48.3664 59.4128 51.7502 64.2367 53.7484C69.0606 55.7465 74.3687 56.2694 79.4897 55.2508C84.6108 54.2322 89.3149 51.7179 93.007 48.026C95.4586 45.5745 97.4033 42.6643 98.7301 39.4613C100.057 36.2582 100.74 32.825 100.74 29.358C100.74 25.891 100.057 22.458 98.7301 19.255C97.4033 16.0519 95.4586 13.1415 93.007 10.69ZM87.276 24.556L85.512 26.3211L77.801 34.0311C76.185 35.6411 74.5733 37.253 72.966 38.867C72.4552 39.3624 71.7716 39.6395 71.06 39.6395C70.3484 39.6395 69.6648 39.3624 69.154 38.867C68.838 38.549 68.521 38.2331 68.212 37.9171C65.9826 35.6811 63.7537 33.4478 61.525 31.2171C61.2749 30.9669 61.0764 30.6698 60.941 30.3428C60.8057 30.0159 60.736 29.6657 60.7361 29.3118C60.7361 28.958 60.8058 28.6075 60.9413 28.2806C61.0767 27.9537 61.2753 27.6567 61.5255 27.4066C61.7757 27.1564 62.0728 26.9579 62.3997 26.8226C62.7266 26.6872 63.077 26.6177 63.4308 26.6177C63.7847 26.6178 64.135 26.6874 64.4619 26.8228C64.7888 26.9583 65.0858 27.1568 65.336 27.4071C65.652 27.7221 65.969 28.0381 66.279 28.3551C67.875 29.9551 69.4687 31.5533 71.06 33.15L78.63 25.5802L83.464 20.7462C83.9788 20.2896 84.6484 20.0467 85.3362 20.0672C86.0239 20.0878 86.6778 20.3703 87.1644 20.8568C87.651 21.3432 87.9335 21.9969 87.9543 22.6846C87.975 23.3724 87.7324 24.0421 87.276 24.557",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.227 58.2438C22.5443 58.2394 16.0699 55.9181 10.9068 51.6754C5.74372 47.4327 2.21136 41.531 0.911637 34.976C-0.38809 28.4209 0.625172 21.618 3.77882 15.7262C6.93248 9.83444 12.0314 5.21831 18.2069 2.66443C24.3823 0.110546 31.2522 -0.222985 37.6461 1.72034C44.04 3.66366 49.5623 7.76378 53.2721 13.3221C56.982 18.8805 58.6498 25.5531 57.9915 32.2032C57.3333 38.8534 54.3896 45.0697 49.662 49.7928C46.9803 52.4779 43.7945 54.6067 40.2876 56.057C36.7807 57.5073 33.0219 58.2504 29.227 58.2438ZM29.227 5.46985C23.7006 5.47384 18.3466 7.39383 14.077 10.9027C9.80752 14.4116 6.88667 19.2923 5.81215 24.7133C4.73763 30.1342 5.57596 35.76 8.18422 40.6322C10.7925 45.5044 15.0093 49.3215 20.1163 51.4332C25.2234 53.5449 30.9046 53.8206 36.1921 52.2133C41.4796 50.6059 46.0462 47.2148 49.1139 42.618C52.1816 38.0213 53.5606 32.5033 53.016 27.0038C52.4714 21.5043 50.0368 16.3636 46.127 12.4579C43.9092 10.2374 41.2745 8.47693 38.3742 7.27771C35.474 6.07849 32.3654 5.46409 29.227 5.46985Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M46.9 10.69C43.2079 6.99801 38.5038 4.48372 33.3828 3.46516C28.2617 2.4466 22.9536 2.96966 18.1297 4.96785C13.3058 6.96603 9.18268 10.3498 6.28186 14.6912C3.38104 19.0327 1.83276 24.1366 1.83276 29.358C1.83276 34.5794 3.38104 39.6835 6.28186 44.025C9.18268 48.3664 13.3058 51.7502 18.1297 53.7484C22.9536 55.7465 28.2617 56.2694 33.3828 55.2508C38.5038 54.2322 43.2079 51.7179 46.9 48.026C49.3516 45.5745 51.2963 42.6643 52.6231 39.4613C53.9499 36.2582 54.6328 32.825 54.6328 29.358C54.6328 25.891 53.9499 22.458 52.6231 19.255C51.2963 16.0519 49.3516 13.1415 46.9 10.69ZM41.169 24.556L39.405 26.3211L31.694 34.0311C30.078 35.6411 28.4663 37.253 26.859 38.867C26.3482 39.3624 25.6646 39.6395 24.953 39.6395C24.2414 39.6395 23.5578 39.3624 23.047 38.867C22.731 38.549 22.415 38.2331 22.105 37.9171C19.8756 35.6811 17.6467 33.4478 15.418 31.2171C15.1679 30.9669 14.9694 30.6698 14.834 30.3428C14.6987 30.0159 14.629 29.6657 14.6291 29.3118C14.6291 28.958 14.6988 28.6075 14.8343 28.2806C14.9697 27.9537 15.1683 27.6567 15.4185 27.4066C15.6688 27.1564 15.9658 26.9579 16.2927 26.8226C16.6196 26.6872 16.97 26.6177 17.3239 26.6177C17.6777 26.6178 18.028 26.6874 18.3549 26.8228C18.6818 26.9583 18.9788 27.1568 19.229 27.4071C19.545 27.7221 19.862 28.0381 20.172 28.3551C21.768 29.9551 23.3617 31.5533 24.953 33.15L32.523 25.5802L37.357 20.7462C37.8718 20.2896 38.5414 20.0467 39.2291 20.0672C39.9169 20.0878 40.5708 20.3703 41.0574 20.8568C41.544 21.3432 41.8266 21.9969 41.8473 22.6846C41.868 23.3724 41.6254 24.0421 41.169 24.557",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }