var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prefooter-blocks-large" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "block-wrap", attrs: { sm: "12", xxl: "3" } },
            [
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("DoubleCheck"),
                  _c("strong", [_vm._v("Double check")]),
                  _c("p", [
                    _vm._v(
                      "\n                    Dopo la conferma online verifichiamo di nuovo la prenotazione qualche giorno prima del tuo arrivo.\n                "
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "block-wrap", attrs: { sm: "12", xxl: "3" } },
            [
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("HeartLarge"),
                  _c("strong", [_vm._v("White list")]),
                  _c("p", [
                    _vm._v(
                      "\n                    La nostra tecnologia e le nostre persone lavorano ogni giorno per proporti solo strutture di provata qualità e professionalità.\n                "
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "block-wrap", attrs: { sm: "12", xxl: "3" } },
            [
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("Headset"),
                  _c("strong", [_vm._v("Assistenza interna")]),
                  _c("p", [
                    _vm._v(
                      "\n                    Ti assistiamo con persone che potrai chiamare per nome. Nessun call center esterno o robot.\n                "
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }